import ApiService from "@/core/services/ApiService";
import JwtService, { TokenType } from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  id: number;
  name: string;
  email: string;
  deleted_at?: string;
  updated_at?: string;
  created_at?: string;
  token?: string;
  readonly_token?: string;
  roles?: string[] | null;
  permissions?: string[] | null;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user: User) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    if (user.token != null) {
      JwtService.saveToken(user.token);
    }
    if (user.readonly_token != null) {
      JwtService.saveToken(user.readonly_token, TokenType.Readonly);
    }
  }

  @Mutation
  [Mutations.SET_USER](user: User) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.SET_AUTH](user: User) {
    return new Promise<void>((resolve) => {
      this.context.commit(Mutations.SET_AUTH, user);
      resolve();
    });
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("auth/login", {
        ...credentials,
        device_name: "admin-panel",
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.message || "Unknown error"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setAuthorizationHeader();
      ApiService.get("auth/me")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            this.context.commit(Mutations.PURGE_AUTH);
          } else {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          }
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
