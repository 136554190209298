const ID_TOKEN_KEY = "id_token" as string;

export enum TokenType {
  Write,
  Readonly,
}

const buildTokenKey = (tokenKey: string, type?: TokenType): string =>
  `${type === TokenType.Readonly ? "read" : "write"}_${tokenKey}`;

/**
 * @description get token form localStorage
 */
export const getToken = (type?: TokenType): string | null => {
  return window.localStorage.getItem(buildTokenKey(ID_TOKEN_KEY, type));
};

/**
 * @description save token into localStorage
 */
export const saveToken = (token: string, type?: TokenType): void => {
  window.localStorage.setItem(buildTokenKey(ID_TOKEN_KEY, type), token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (type?: TokenType): void => {
  window.localStorage.removeItem(buildTokenKey(ID_TOKEN_KEY, type));
};

export default { getToken, saveToken, destroyToken };
