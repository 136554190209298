import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import * as OrderDetails from "../../modules/orders/interfaces/OrderDetails.interface";

interface OrderInfo {
  order?: OrderDetails.Order;
}

@Module
export default class OrderInfoModule extends VuexModule implements OrderInfo {
  order: undefined;

  @Mutation
  [Mutations.SET_ORDER_INFO](payload): void {
    this.order = payload;
  }

  @Action
  [Actions.SET_ORDER_INFO_ACTION](payload) {
    this.context.commit(Mutations.SET_ORDER_INFO, payload);
  }
}
