import { App } from "vue";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import JwtService, { TokenType } from "@/core/services/JwtService";

export default class Helpers {
  public static init(app: App<Element>): void {
    const formatterEUR = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "EUR",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const formatter = new Intl.NumberFormat("en-US");

    app.config.globalProperties.$filters = {
      currency(value: number) {
        return formatter.format(value);
      },
      currencyEUR(value: number) {
        return formatterEUR.format(value);
      },
      dateToString(date: Date): string {
        return DateTime.fromJSDate(date).toFormat("yyyy-LL-dd");
      },
      checkUserPermission(permission: string): boolean {
        return useStore().getters.currentUser.permissions.includes(permission);
      },
      appendTokenToUrl(url: string): string {
        const readonlyToken = JwtService.getToken(TokenType.Readonly);
        return `${url}?api_token=${readonlyToken}`;
      },
    };
  }
}
