import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/LayoutWrapper.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/BlankPage.vue"),
      },
      {
        path: "orders",
        component: () => import("@/modules/orders/views/OrdersRouter.vue"),
        children: [
          {
            path: "",
            name: "orders",
            component: () =>
              import("@/modules/orders/views/OrdersResourcePage.vue"),
          },
          {
            path: ":id",
            name: "orders-view",
            component: () =>
              import("@/modules/orders/views/OrdersViewPage.vue"),
          },
        ],
      },
      {
        path: "customers",
        component: () =>
          import("@/modules/customers/views/CustomersRouter.vue"),
        children: [
          {
            path: "",
            name: "customers",
            component: () =>
              import("@/modules/customers/views/CustomersResourcePage.vue"),
          },
          {
            path: ":id",
            name: "customers-view",
            component: () =>
              import("@/modules/customers/views/CustomerViewPage.vue"),
          },
          {
            path: "create",
            name: "customers-create",
            component: () =>
              import("@/modules/customers/views/CustomerCreatePage.vue"),
          },
        ],
      },
      {
        path: "payments",
        component: () => import("@/modules/payments/views/PaymentsRouter.vue"),
        children: [
          {
            path: "refunds",
            name: "payments-refunds",
            component: () =>
              import("@/modules/payments/views/PaymentRefundsIndexPage.vue"),
          },
          {
            path: "refunds",
            name: "payments-refunds-view",
            component: () =>
              import("@/modules/payments/views/PaymentRefundsResourcePage.vue"),
          },
        ],
      },
      {
        path: "unsynced-orders",
        component: () =>
          import("@/modules/orders/views/UnsyncedOrdersRouter.vue"),
        children: [
          {
            path: "",
            name: "unsynced-orders",
            component: () =>
              import("@/modules/orders/views/UnsyncedOrdersResourcePage.vue"),
          },
        ],
      },
      {
        path: "vouchers",
        component: () => import("@/modules/vouchers/views/VouchersRouter.vue"),
        children: [
          {
            path: "",
            name: "vouchers",
            component: () =>
              import("@/modules/vouchers/views/VouchersResourcePage.vue"),
          },
          {
            path: ":id",
            name: "vouchers-view",
            component: () =>
              import("@/modules/vouchers/views/VouchersViewPage.vue"),
          },
          {
            path: "create",
            name: "vouchers-create",
            component: () =>
              import("@/modules/vouchers/views/VoucherCreatePage.vue"),
          },
        ],
      },
      {
        path: "pathfinder",
        component: () => import("@/modules/pathfinder/views/PathfinderRouter.vue"),
        children: [
          {
            path: "",
            name: "vouchers",
            component: () =>
              import("@/modules/pathfinder/views/PathfinderResourcePage.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/modules/auth/views/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/modules/auth/views/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
