import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Layout {
  currentLayout?: string;
}

@Module
export default class LayoutsModule extends VuexModule implements Layout {
  currentLayout = undefined;

  @Mutation
  [Mutations.SET_LAYOUT](payload): void {
    this.currentLayout = this.currentLayout + payload;
  }

  @Action
  [Actions.SET_LAYOUT_ACTION](payload) {
    this.context.commit(Mutations.SET_LAYOUT, payload);
  }
}
