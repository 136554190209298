import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import OrderInfoModule from "@/store/modules/OrderInfoModule";
import ResourcesModule from "@/store/modules/ResourcesModule";
import LayoutsModule from "@/store/modules/LayoutsModule";

config.rawError = true;
const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    OrderInfoModule,
    ResourcesModule,
    LayoutsModule,
  },
});

export default store;
