import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface StoreInfo {
  cache: Record<string, unknown>;
}

@Module
export default class ResourcesModule extends VuexModule implements StoreInfo {
  cache = {};

  get getResourceCache() {
    return (resource: string) => this.cache[resource];
  }

  @Mutation
  [Mutations.CACHE_RESOURCE_DATA](payload: {
    resource: string;
    data: unknown;
  }): void {
    this.cache[payload.resource] = payload.data;
  }

  @Action
  [Actions.SET_RESOURCE_DATA](payload: { resource: string; data: unknown }) {
    this.context.commit(Mutations.CACHE_RESOURCE_DATA, payload);
  }
}
